<template>
  <div id="app">
    <v-app id="inspire">
      <span class="backroundimage"></span>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md7>
            <v-card class="elevation-12" color="#3fbec7f5">
              <div class="logo">
                <v-img src="https://www.campnageela.org/images/NageelaLogoMed.png"></v-img>
              </div>
              <div v-if="allowedToReset == false">
                <h3 class="text-center">Please Enter Your Password Reset Code</h3>
                <v-card-text>
                  <v-form ref="form1" v-model="valid" lazy-validation>
                    <v-text-field
                      label="Reset Code"
                      name="login"
                      prepend-icon="mdi-lock-reset"
                      type="text"
                      autocomplete="off"
                      :rules="[v => !!v || 'Token is required']"
                      v-model="enteredToken"
                    ></v-text-field>
                  </v-form>

                  <div class="danger-alert" v-html="error" />
                  <v-card-text></v-card-text>
                </v-card-text>

                <v-card-actions class="justify-center">
                  <v-btn
                    rounded
                    large
                    :loading="loading1"
                    @click="verifyToken"
                    :disabled="loading1 ||!valid"
                    color="primary"
                  >
                    Reset Password
                    <v-icon>mdi-lock-open-variant-outline</v-icon>
                  </v-btn>
                </v-card-actions>
              </div>

              <div v-if="allowedToReset == true">
                <h3 class="text-center">Please Enter Your New Password</h3>

                <v-card-text>
                  <v-form ref="form2" v-model="valid2" lazy-validation>
                    <div class="justify-center">
                      <v-text-field
                        label="New Password"
                        name="login"
                        prepend-icon="mdi-lock-reset"
                        autocomplete="off"
                        v-model="password"
                        v-on:keyup.enter="register"
                        :disabled="loading1"
                        :type="showPassword ? 'text' : 'password'"
                        :rules="[v => !!v || 'Password is required', v => (v && v.length >= 6) || 'Password must have more than 6 characters']"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                      ></v-text-field>
                    </div>
                  </v-form>

                  <div class="danger-alert" v-html="error" />
                  <v-card-text></v-card-text>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    rounded
                    large
                    :loading="loading2"
                    @click="resetPassword"
                    :disabled="loading2 ||!valid2"
                    color="primary"
                  >
                    Reset Password
                    <v-icon>mdi-lock-open-variant-outline</v-icon>
                  </v-btn>
                </v-card-actions>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-app>
  </div>
</template>
<script>
// @ is an alias to /src
import userService from "../services/Users";

export default {
  name: "ResetPassword",
  components: {},
  data() {
    return {
      valid: null,
      valid2: null,
      loading1: false,
      loading2: false,
      allowedToReset: false,
      enteredToken: null,
      TokenInfo: [],
      password: null,
      error: null,
      showPassword: null
    };
  },
  async mounted() {
    this.enteredToken = this.$route.query.token || null;
  },
  computed: {},
  methods: {
    async verifyToken() {
      if (this.$refs.form1.validate())
        try {
          this.loading1 = true;
          const response = await userService.verifyToken({
            ResetPasswordToken: this.enteredToken
          });
          this.loading1 = false;
          this.error = null;
          this.allowedToReset = true;
        } catch (err) {
          console.log(err);
          this.error = err.response.data.error;
          this.loading1 = false;
        }
    },
    async resetPassword() {
      if (this.$refs.form2.validate())
        try {
          this.loading2 = true;
          const response = await userService.resetPassword({
            ResetPasswordToken: this.enteredToken,
            Password: this.password
          });
          this.loading2 = false;
          this.$router.push({ name: "Login" });
        } catch (err) {
          console.log(err);
          this.error = err.response.data.error;
          this.loading2 = false;
        }
    }
  }
};
</script>
<style scoped>
/*to be removed */

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  margin: 0 auto;
}
.backroundimage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../assets/login_background.jpg")
    no-repeat center center;
  background-size: cover;
  filter: brightness(50%);
}
</style>